import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import { useNavigate } from "react-router";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import useApiAxios from "../../api/useApiAxios";
import StripeLoadingMsg from "../../components/StripeLoadMsg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPEId);

/** Used for one-time stripe payments with membership following one time payment*/
const Upfront = () => {
  const navigate = useNavigate();
  const { stripe_id } = queryString.parse(window.location.search);
  const { getUpfrontSecret, getSessionStorageData } = useApiAxios();
  const price = getSessionStorageData()?.price;
  const salesforce_opp_id = getSessionStorageData()?.salesforce_opp_id;

  const [clientSecret, setClientSecret] = useState("");

  const handleComplete = () => {
    console.log("One-time payment completed");

    const path = `/membership?stripe_id=${stripe_id}`;
    console.log(path);
    navigate(path, { replace: true });
  };
  const fetchData = async () => {
    const res = await getUpfrontSecret(stripe_id, price, salesforce_opp_id);
    setClientSecret(res?.data?.checkout_client_secret);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="upfront">
      {clientSecret ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret, onComplete: handleComplete }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <StripeLoadingMsg />
      )}
    </div>
  );
};

export default Upfront;
