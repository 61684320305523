import React, { useContext } from "react";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link, Navigate } from "react-router-dom";
import logo from "../../assets/image/logo.png";
import { Context } from "../../context/Context";

const Login = () => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { loginApi } = useApiAxios();
  const [error, setError] = useState({ className: "hidden", message: "" });
  const [username, password] = watch(["username", "password"]);
  const { isLoading, isAuthorized } = useContext(Context);

  useEffect(() => {
    setError({ className: "hidden", message: "" });
  }, [username, password]);
  const { LogoutApi } = useApiAxios();
  const [showPassword, setShowPassword] = useState(false);
  console.log(errors);
  return (
    <div className="login-page mt-5 pt-5">
      <div className="col-lg-8 m-auto ">
        <div className="bg-white shadow">
          <div className="row align-items-center">
            <div className="col-md-6 align-self-stretch">
              <div className="form-right h-100 bg-primary text-white text-center pt-5">
                <h2 className="fs-1 pt-0 pt-md-5 mt-0 mt-md-5 mb-5">
                  Login Now
                </h2>
              </div>
            </div>
            <div className="col-md-6 p-5">
              <form
                onSubmit={handleSubmit(async (data) => {
                  console.log(data);
                  const response = await loginApi(data);
                  if (response.error) {
                    setError({
                      className: "",
                      message: response.message,
                    });
                  }
                })}
              >
                <div className="row">
                  <div className="col-12 mb-3">
                    <label className="fw-bold">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                      id="exampleInputEmail1"
                      {...register("username", {
                        required: "Email is required.",
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message: "Enter a valid email address",
                        },
                        maxLength: {
                          value: 64,
                          message: "Enter a valid email address",
                        },
                        minLength: {
                          value: 5,
                          message: "Enter a valid email address",
                        },
                      })}
                      aria-describedby="emailHelp"
                    />
                    {errors.username && (
                      <div className="invalid-feedback">
                        {errors.username.message}
                      </div>
                    )}
                  </div>
                  <div className="col-12 mb-3">
                    <label className="fw-bold">Password</label>
                    <input
                      placeholder="Enter your password"
                      {...register("password", {
                        required: "Password is required.",

                        maxLength: {
                          value: 16,
                          message: "Max length is 16 characters",
                        },
                        minLength: {
                          value: 8,
                          message: "Min length is 8 characters",
                        },
                      })}
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                    {errors.password && (
                      <div className="invalid-feedback">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="checkbox"
                          className="form-check-input ms-0 me-2"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          Keep me logged in
                        </label>
                      </div>
                      <div style={{ textAlign: "right" }} className="col-6">
                        <Link to="/forget-password">Forget Password</Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <button type="submit" className="btn btn_dark">
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
