import React, { useEffect,useState, useContext } from "react";
import Select from "react-select";
import initialData from "./../../data/data.json"; // Rename imported data for clarity
import BulkVolumeRemoval from "../bulkVolumeRemoval/BulkVolumeRemoval";
import SingleItemRemoval from "../singleItemRemoval/SingleItemRemoval";
import { useNavigate } from "react-router-dom";
import { Context } from "./../../context/Context";
import useApiAxios from "../../api/useApiAxios";
const data = [
  {
    title: "If the job is more than $78 today:",
    subPoint: [
      "✓ we'll automatically schedule an appointment for them the following year,",
      "✓ You will receive an email and text message alerting them to the date/time of their appointment. ",
      "✓ You will receive 1/6 of a load for free at that time of your next service. ",
      "✓ 42% off the list price. ",
      "✓ Any volume amount beyond 1/6 of a load is at a discounted price (the difference between your pick-up volume amount and $268). ",
      "✓ If they need to pick a different date/time there will be a link to do so.  ",
      "✓ On the 1st of the following month we will bill you $13.00",
    ],
  },
  {
    title: "If the job is $78 or less today:",
    subPoint: [
      "The customer receives a `free` pickup when agreeing to the $13/month. ",
      "✓we'll automatically schedule an appointment for them the following year.",
      "✓ You will receive an email and text message alerting them to the date/time of their appointment. ",
      "✓ You will receive 1/6 of a load for free at that time of your next service.  ",
      "✓ 42% off the list price.  ",
      "✓ Any volume amount beyond 1/6 of a load is at a discounted price (the difference between your pick-up volume amount and $268).  ",
      "✓ If they need to pick a different date/time there will be a link to do so.  ",
      "✓ On the 1st of the following month we will bill you $13.00",
    ],
  },
  {
    title: "Terms & Conditions:",
    subPoint: [
      " • Customer is committing to 6 months of membership at $13/month.  Any cancelations at any time in the first 6 months we will bill $78.",
      "• The customer is getting 1/6 of a truckload of yearly value.  Specialty/surcharge items are not included.  Things like:",
      " • Tires",
      "• Hazardous items",
      " • Any item that requires special disposal requirements",
      " • Extra heavy/bulky/dense items - safes, gravel, dirt, etc",
      " • If you don't need the 1/6 of a load pickup on your 1 year anniversary, you can gift it to friends/family - contact us to coordinate.",
      " • If you need an extra pickup at any point in the year, you'll get it for $156 for the first 1/6 of a load which will be due at the time of service.",
    ],
  },
];

const Home = () => {
  const navigate = useNavigate();
  const { LogoutApi } = useApiAxios();
  const { isLoading, isAuthorized } = useContext(Context);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [regionData, setRegionData] = useState({}); // Renamed state variable to regionData
  useEffect(() => {
    if (selectedRegion) {
      // Use initialData to get the region specific data
      setRegionData(initialData.regions[selectedRegion]);
    }
  }, [selectedRegion]);

  const regionOptions = [
    { value: 'Kansas City', label: 'Kansas City' },
    { value: 'Denver/Boulder', label: 'Denver/Boulder' },
    { value: 'Indianapolis', label: 'Indianapolis' }
    // Add more regions as needed
  ];
  return (
    <div className="row">
      <div className="col-lg-6 mb-4">
      <div className="col-12 mb-3">
      <div className="homeBox rounded p-4 mb-3">
      <h2 className="colorWhite mb-3 text-center">Region Selection</h2>
        <Select
          options={regionOptions}
          onChange={(selectedOption) => setSelectedRegion(selectedOption.value)}
          placeholder="Select a Region"
          className="basic-single"
          classNamePrefix="select"
        />
        </div>
      </div>
      <BulkVolumeRemoval selectedRegion={selectedRegion}/>
      <SingleItemRemoval selectedRegion={selectedRegion} />
      </div>

      <div className="col-lg-6 mb-4">
      <>
      <div>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', borderColor: 'rgb(0, 0, 0)'}}><span style={{boxSizing: 'border-box', borderColor: 'rgb(0, 0, 0)'}}><font size={4} face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}><u style={{borderColor: 'rgb(0, 0, 255)'}}>Join Now, and Save Big on Today's Job!</u></font></span></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', borderColor: 'rgb(0, 0, 0)'}}><span style={{boxSizing: 'border-box', borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>
      </font></span></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', borderColor: 'rgb(0, 0, 0)'}}><span style={{boxSizing: 'border-box', borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>$78 Instant Savings:</font><font style={{borderColor: 'rgb(33, 37, 41)', color: 'rgb(33, 37, 41)'}}> Sign up for our membership program now and get an immediate discount of $78 on your service today.</font></font></span></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', borderColor: 'rgb(0, 0, 0)'}}><span style={{boxSizing: 'border-box', borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(33, 37, 41)', color: 'rgb(33, 37, 41)'}}>
      </font></span></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(33, 37, 41)', color: 'rgb(33, 37, 41)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(33, 37, 41)'}}><span style={{boxSizing: 'border-box', borderColor: 'rgb(33, 37, 41)'}} /></font></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', borderColor: 'rgb(0, 0, 0)'}}><span style={{boxSizing: 'border-box', borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>A Job Under $78? It's On Us!</font><font style={{borderColor: 'rgb(33, 37, 41)', color: 'rgb(33, 37, 41)'}}> If your junk removal job costs less than $78 today, congratulations - it's free with your new active membership!</font></font></span></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(33, 37, 41)', color: 'rgb(33, 37, 41)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(33, 37, 41)'}}><span style={{boxSizing: 'border-box', borderColor: 'rgb(33, 37, 41)'}} /></font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(33, 37, 41)', color: 'rgb(33, 37, 41)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(33, 37, 41)'}}>
    </font></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><strong style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>Here’s what awaits you:</font> <font style={{borderColor: 'rgb(11, 83, 148)', color: 'rgb(11, 83, 148)'}}>📆</font></font></strong></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Automatic Scheduling</strong>: We’ve got you covered! When you are ready to set up your annual free 1/6 load pickup appointment for next year, give us a shout, and we will automatically schedule it for you!</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Stay Updated: </strong>Keep an eye on your inbox and phone - we’ll send you an email and text with all the details about the date and time.</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Need to Reschedule?</strong> Life happens! If you need to change the date or time, contact us to reschedule.</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}} /></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><strong style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>Annual Free Pickup Perks:</font> <font style={{borderColor: 'rgb(11, 83, 148)', color: 'rgb(11, 83, 148)'}}>🚛</font></font></strong></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Enjoy a Freebie:</strong> Remember that 1/6 of a truckload is on us when your next service rolls around!</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>💰Huge Savings:</strong> Get ready to save big with a whopping 42% discount off our list prices for your annual pickup if it is more significant than 1/6 load. Not sure what size? Call us for a free estimate!</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}} /></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><strong style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>Share the Love! </font><font style={{borderColor: 'rgb(11, 83, 148)', color: 'rgb(11, 83, 148)'}}>🎁</font></font></strong></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Not using your free annual 1/6 load pickup?</strong> You can gift it to friends or family. Just let us know, and we'll make the arrangements. (They must be located in the same service area.)</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}} /></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><strong style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>15% Member Discount: </font><font style={{borderColor: 'rgb(11, 83, 148)', color: 'rgb(11, 83, 148)'}}>💰</font></font></strong></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Declutter at a Discount: </strong>Enjoy 15% off any JUNK service outside of your Annual Free Pickup.</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}} /></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><strong style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>Seamless Billing:</font><font style={{borderColor: 'rgb(11, 83, 148)', color: 'rgb(11, 83, 148)'}}>📱</font></font></strong></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Easy Payments:</strong> Expect your membership dues to be automatically drafted on the 1st of every month. <u style={{borderColor: 'rgb(51, 51, 51)'}}>Please keep an eye out for Stripe.com emails.</u> This is where your receipts, payment method update reminders, and other financial information will come from.</font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}} /></p>
  <p style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><strong style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 0)'}}><font style={{borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>Stay Connected:</font> <font style={{borderColor: 'rgb(11, 83, 148)', color: 'rgb(11, 83, 148)'}}>📩</font></font></strong></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Need Assistance?</strong> Reach out anytime at: <strong style={{borderColor: 'rgb(230, 145, 56)', color: 'rgb(230, 145, 56)'}}>(913) 444-9386</strong> or email us at <strong style={{borderColor: 'rgb(230, 145, 56)', color: 'rgb(230, 145, 56)'}}><a href="mailto:membership@1800gotjunkmembership.com" target="_blank" style={{pointerEvents: 'none', cursor: 'default', borderColor: 'rgb(66, 133, 244)', color: 'rgb(66, 133, 244)'}}>membership@<wbr style={{borderColor: 'rgb(66, 133, 244)'}} />1800gotjunkmembership.com</a></strong>. </font></p>
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box', lineHeight: '20px', marginBottom: '1rem', marginTop: '0px', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(33, 37, 41)', color: 'rgb(33, 37, 41)'}} />
  <p style={{fontSize: '16px', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', fontFamily: '-apple-system, HelveticaNeue', borderColor: 'rgb(51, 51, 51)', color: 'rgb(51, 51, 51)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(51, 51, 51)'}}>● <strong style={{borderColor: 'rgb(51, 51, 51)'}}>Your Member Portal:</strong> For all your account details, <strong style={{borderColor: 'rgb(51, 51, 51)'}}>terms &amp;amp; conditions</strong>, and privacy policy, visit <a href="http://www.1800gotjunkmembership.com/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.1800gotjunkmembership.com&amp;source=gmail&amp;ust=1712804319357000&amp;usg=AOvVaw2ZmKfXNREiB7jSBH-7lHPA" style={{pointerEvents: 'none', cursor: 'default', borderColor: 'rgb(11, 83, 148)', color: 'rgb(11, 83, 148)'}}>www.1800gotjunkmembership.com</a>. Log in with your registered email and password.</font></p>
  <div style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><b style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>
      </font></b></div><div style={{fontSize: '16px', caretColor: 'rgb(255, 255, 255)', fontFamily: '-apple-system, HelveticaNeue', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', textAlign: 'center', borderColor: 'rgb(0, 0, 0)'}}><b style={{borderColor: 'rgb(0, 0, 0)'}}><font face="tahoma, sans-serif" style={{fontFamily: 'tahoma, sans-serif', borderColor: 'rgb(0, 0, 255)', color: 'rgb(0, 0, 255)'}}>***Please carefully review the terms and conditions linked at the bottom of this page before activating your membership. Minimum time as an active membered is required***</font></b></div>
</div>

</>

      </div>
      {/* <div className="col-md-6 p-4">
          {data.map((item, index) => (
            <div className="row" key={index}>
              <div className="col-12 my-2 d-flex justify-content-left align-items-center">
                <h3>{item.title}</h3>
              </div>
              {item.subPoint.map((subItem, index2) => (
                <div
                  key={index2}
                  className="col-12  d-flex justify-content-left align-items-center"
                  style={{ lineHeight: "100%" }}
                >
                  <p>{subItem}</p>
                </div>
              ))}
            </div>
          ))}
        </div> */}
    </div>
  );
};

export default Home;
