import React from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

const TermsConditions = () => {
  return (
    <div className="container">
      <div className="py-5 privacy_policy">
        <h3 className="text-3xl mb-1 font-bold pt-5">
          SWVE Management Holdco, LLC’s (“SWVE”) PRIVACY POLICY
        </h3>
        <h2 className="text-2xl mb-3 font-bold py-5">Effective Date.</h2>
        <p>This Privacy Policy was last updated on December 1, 2023.</p>
        <p>
          1800gotjunkmembership.com (the “Website”) is owned and operated by
          SWVE Management Holdco, LLC’s (“SWVE”) , along with DreamLawn, MVP
          Heating, Cooling, Electric, Plumbing, 1-800-GOT-JUNK?, You Move Me,
          oneOS Home (collectively “SWVE”, “us”, “we” or “our”). This Privacy
          Policy, together with our{" "}
          <span className="text-decoration-line: underline">
            Terms and Conditions
          </span>{" "}
          (collectively, the “Agreement”) form a legally binding agreement
          between you (“you” or “your”) and SWVE. This Agreement governs your
          access to and use of any SWVE website and your use or attempted use of
          our products and services (collectively, “Your Use”). Your Use of the
          Website shall be deemed to constitute your consent to be bound by this
          Agreement and shall be enforceable in the same way as if you had
          signed this Agreement.
        </p>
        <h3 className="text-2xl mb-3 font-bold my-3">Contents</h3>
        <p>
          <AnchorLink offset="80" href="#1">
            1. Scope of the Policy
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#2">
            2. Information We Collect
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#3">
            3. How We Use Your Information
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#4">
            4. How We Share Your Information
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#5">
            5. How We Store and Protect your Information
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#6">
            6. Your Rights and Choice Regarding Your Data
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#7">
            7. Third Party Links and Behavioral Tracking
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#8">
            8. Children’s Data
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#9">
            9. Changes to This Privacy Policy
          </AnchorLink>
        </p>
        <p>
          <AnchorLink offset="80" href="#10">
            10.Contact Us
          </AnchorLink>
        </p>
        <h3 id="1" className="text-2xl mb-3 font-bold my-3">
          1. SCOPE OF THE POLICY
        </h3>
        <p>
          This Privacy Policy applies to all visitors to the Website and all
          SWVE’s websites and services, including any apps, websites, features,
          and other services. Please remember that Your Use of the Website is
          also subject to our{" "}
          <span className="text-decoration-line: underline">
            Terms and Conditions.
          </span>
        </p>
        <h3 id="2" className="text-2xl mb-3 font-bold my-3">
          2. INFORMATION WE COLLECT
        </h3>
        <h3 className="text-1xl mb-3 font-bold my-3">
          A. Information You Provide to Us
        </h3>
        <p>
          When you provide information to SWVE, such as your name, telephone
          number, address, billing information, information related to services
          provided, surveys, or offers, or other personal information, we
          collect that information.
        </p>
        <p>
          When you contact us or we contact you, we collect any information that
          you provide, including the contents of the messages or attachments you
          send us.
        </p>
        <h3 className="text-1xl mb-3 font-bold my-3">
          B. Information We Collect When You Use the Website
        </h3>
        <p>
          <span className="font-bold">
            Cookies, Analytics, and Third-Party Technologies.
          </span>{" "}
          We collect information using “cookies”, tracking pixels, data
          analytics tools like Google Analytics, SDKs, and other third-party
          technologies to understand how you navigate through the Website, to
          improve your site experience, to serve you better ads on other sites,
          and to save your preferences. You should consult your web browser(s)
          to modify your cookie settings. Please note that if you delete or
          choose not to accept cookies from us, you may miss out on certain
          features of the Website.
        </p>
        <p>
          SWVE also collects some anonymous information each time you visit the
          Website so we can improve the overall quality of your online
          experience. We collect your IP address, referral data, browsing
          history, browser, and platform type. You do not have to register with
          SWVE before we can collect this anonymous information. We do not
          collect any unique information about you (such as your name, email,
          etc.) except when you specifically and knowingly provide such
          information.{" "}
        </p>
        <p>
          If you request more information on a product or service, we will
          collect your personal information only so that we can get you the
          information you have requested. Like most standard website servers, we
          use log files to collect and store this anonymous user information. We
          analyze this information to examine trends, administer the site, track
          user's movement in the aggregate, and gather broad demographic
          information for aggregate use. SWVE may present links in a format that
          enables us to understand whether they have been followed. We use this
          information to understand and improve the quality of the Website.
        </p>
        <h3 id="3" className="text-2xl mb-3 font-bold my-3">
          3. HOW WE USE YOUR INFORMATION
        </h3>
        <p className="ms-2">We use your personal information to:</p>
        <ul className="ms-4">
          <li>● Communicate with you;</li>
          <li>● Provide customer support;</li>
          <li>● Improve the Website; and</li>
          <li>● Respond to legal proceedings and obligations.</li>
        </ul>
        <h3 className="text-1xl mb-3 font-bold my-3">Communicate With You.</h3>
        <p>
          We may use the information we collect from you to provide you
          additional information on goods or services provided by our subsidiary
          companies, i.e., home services. This may include calls and SMS at the
          number you have provided or additional marketing communications by
          email.
        </p>

        <h3 className="text-1xl mb-3 font-bold my-3">
          Provide Customer Support.
        </h3>
        <p className="ms-2">
          We work hard to provide you with the best experience possible,
          including supporting you when you need it. To do this, we use your
          personal information to:
        </p>
        <ul className="ms-4">
          <li>
            ● Assist you in resolving questions or issues you have regarding the
            Website;{" "}
          </li>
          <li>● Process transactions; and</li>
          <li>● Provide you support or respond to you.</li>
        </ul>

        <h3 className="text-1xl mb-3 font-bold my-3">
          Provide Customer Support.
        </h3>
        <p className="ms-2">
          We are always working to improve your experience and provide you with
          new and helpful features. To do this, we use your personal information
          to:
        </p>
        <ul className="ms-4">
          <li>● Perform research, testing, and analysis;</li>
          <li>● Develop new products, features, partnerships, and services;</li>
          <li>● Personalize your user experience; </li>
          <li>
            ● Prevent, find, and resolve software or hardware bugs and issues;
            and{" "}
          </li>
          <li>
            ● Monitor and improve our operations and processes, including
            security practices, algorithms, and other modeling.{" "}
          </li>
        </ul>

        <h3 className="text-1xl mb-3 font-bold my-3">
          Respond to Legal Proceedings and Obligations.
        </h3>
        <p className="ms-2">
          Occasionally the law, government entities, or other regulatory bodies
          impose demands and obligations on us with respect to the services we
          seek to provide. In such a circumstance, we may use your personal
          information to respond to those demands or obligations.
        </p>

        <h3 id="4" className="text-2xl mb-3 font-bold my-3">
          4. HOW WE SHARE YOUR INFORMATION
        </h3>
        <h3 className="text-1xl mb-3 font-bold my-3">
          Sharing With Third Parties for Business Purposes.
        </h3>
        <p>
          We do not sell, trade, or rent your personal information. We may share
          your personal information with other companies or individuals in any
          of the following limited circumstances:
        </p>
        <ul className="ms-4">
          <li>
            ● To provide such information to trusted businesses or persons for
            the sole purpose of processing personal information on our behalf.
            When this is done, it is subject to agreements that require those
            parties to process such information only on our instructions and in
            compliance with this Privacy Policy and appropriate confidentiality
            and security measures.
          </li>
          <li>
            ● If you have an account with us, we may share the information
            submitted under your account among all our services to provide you
            with a seamless experience and to improve the quality of our
            services. We will not disclose your account information to other
            people or non-affiliated companies, except in the limited
            circumstances described in this Privacy Policy or with your consent.
          </li>
          <li>
            ● We may store and process personal information collected on our
            site in Canada or any other country in which SWVE or its agents
            maintain facilities. By using our services, you consent to the
            transfer of your information among these facilities, including those
            located outside of your country.{" "}
          </li>
          <li>
            ● SWVE may use third-party service providers to serve ads and emails
            on our behalf. These companies may use technologies to measure the
            effectiveness of ads and emails and use information about your
            visits to SWVE so that we can provide a better experience to you.
            For this purpose, we may also provide these third-party service
            providers anonymous information about your site navigation. You can
            remove your email by contacting{" "}
            <Link
              to={"mailto:consumerdata@wearesouthwind.com"}
              className="text-sky-800"
            >
              consumerdata@wearesouthwind.com
            </Link>
            .
          </li>
          <li>
            ● We may share certain information about you with other SWVE
            companies and subsidiaries, including those who may offer services
            that complement those provided by us or which we believe may be of
            interest to you.
          </li>
          <li>
            ● We may also disclose information to third parties who assist us in
            providing our services to you. For example, we may engage third
            parties to make improvements on the Website, to offer new products,
            and to allow us to customize your experience on the Website.
          </li>
        </ul>
        <h3 className="text-1xl mb-3 font-bold my-3">For Legal Reasons.</h3>
        <p>
          We may share your personal information in response to a legal
          obligation, or if we have determined that sharing your personal
          information is reasonably necessary or appropriate to:
        </p>
        <ul className="ms-4">
          <li>
            ● Comply with any applicable federal, state, or local law or
            regulation, civil, criminal, or regulatory inquiry, investigation or
            legal process, or enforceable governmental request;
          </li>
          <li>
            ● Respond to legal process (such as a search warrant, subpoena,
            summons, or court order);
          </li>
          <li>
            ● Enforce our{" "}
            <spam className="text-decoration-line: underline">
              Terms and Conditions;
            </spam>{" "}
          </li>
          <li>
            ● Cooperate with law enforcement agencies concerning conduct or
            activity that we reasonably and in good faith believe may violate
            federal, state, or local law; or
          </li>
          <li>
            ● Exercise or defend legal claims, protect against harm to our
            rights, property, interests, or safety or the rights, property,
            interests, or safety of you, third parties, or the public as
            required or permitted by law.
          </li>
        </ul>
        <h3 className="text-1xl mb-3 font-bold my-3">
          In Connection with a Sale or Merger.
        </h3>

        <p>
          We may share your personal information while negotiating or in
          relation to a change of corporate control such as a restructuring,
          merger, or sale of our assets.
        </p>
        <h3 className="text-1xl mb-3 font-bold my-3">
          Otherwise With Your Consent.
        </h3>

        <p>
          With your permission or upon your direction, we may disclose your
          personal information to interact with a third party or for other
          purposes.
        </p>
        <h3 id="5" className="text-2xl mb-3 font-bold my-3">
          5. HOW WE STORE AND PROTECT YOUR INFORMATION
        </h3>
        <p>
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure, or destruction of
          data. We restrict access to your personally identifying information to
          employees who need to know that information to operate, develop or
          improve our services.
        </p>
        <h3 id="6" className="text-2xl mb-3 font-bold my-3">
          6. YOUR RIGHTS AND CHOICE REGARDING YOUR DATA
        </h3>
        <h3 className="text-1xl mb-3 font-bold my-3">A. All Users.</h3>
        <p>
          <strong className="font-bold">Review and Request Changes.</strong> You
          can always unsubscribe from our commercial or promotional emails by
          clicking unsubscribe in those messages. We will still send you
          transactional and relational emails about Your Use of the Website. .{" "}
          <Link
            to={"mailto:consumerdata@wearesouthwind.com"}
            className="text-sky-800"
          >
            consumerdata@wearesouthwind.com
          </Link>
          .
        </p>
        <p>
          <strong className="font-bold">Email Subscriptions. </strong>
          You can always unsubscribe from our commercial or promotional emails
          by clicking unsubscribe in those messages. We will still send you
          transactional and relational emails about Your Use of the Website.
        </p>
        <p>
          <strong className="font-bold">Cookie Tracking </strong>
          You can modify your cookie settings on your browser, but if you delete
          or choose not to accept our cookies, you may be missing out on certain
          features of the Website.
        </p>
        <p>
          <strong className="font-bold">“Do Not Track” Signals</strong>
          Your browser may offer you a “Do Not Track” option, which allows you
          to signal to operators of websites, web applications, and services
          that you do not want your online activities tracked. We honor “Do Not
          Track” signals and do not track, plant cookies, or use advertising
          when a “Do Not Track” browser mechanism is in place.
        </p>
        <h3 className="text-1xl mb-3 font-bold my-3">
          B. Specific State Rights.
        </h3>
        <h3 className="text-1xl mb-3 font-bold my-3">
          1. California Residents.{" "}
        </h3>
        <p>
          <strong className="font-bold"> Shine the Light.</strong> Cal. Civ.
          Code § 1798.83 permits customers of SWVE who are California residents
          to request certain information regarding and/or opt-out of our
          disclosure of personal information to third parties for their direct
          marketing purposes. To make such a request, please email us at{" "}
          <Link
            to={"mailto:consumerdata@wearesouthwind.com"}
            className="text-sky-800"
          >
            consumerdata@wearesouthwind.com
          </Link>{" "}
          and include your full name, residential address, email, and phone
          number, along with why you are writing, so that we can process your
          request.{" "}
        </p>
        <p>
          {" "}
          <strong className="font-bold">CCPA.</strong> The California Consumer
          Privacy Act (“CCPA”) and the California Privacy Rights Act (“CPRA”)
          provide California residents with the additional rights listed below.
        </p>
        <p>
          {" "}
          <strong className="font-bold">Right to Know.</strong> You have the
          right to know and see what data we have collected about you over the
          past 12 months preceding your request, including:
        </p>
        <ul className="ms-4">
          <li>
            ● The categories of personal information we have collected about
            you;
          </li>
          <li>
            ● The categories of sources from which the personal information has
            been collected;
          </li>
          <li>
            ● The business or commercial purpose(s) for collecting your personal
            information;
          </li>
          <li>
            ● The categories of third parties with whom we have shared your
            personal information; and
          </li>
          <li>
            ● The specific pieces of personal information we have collected
            about you.
          </li>
        </ul>
        <p>
          <strong className="font-bold"> Right to Delete. </strong> You have the
          right to request that we delete the personal information we have
          collected about you unless we need to retain that information for a
          specified purpose as exempted under state law.
        </p>
        <p>
          <strong className="font-bold"> Right to Opt Out. </strong>You have the
          right to opt out of the sharing of your personal information to third
          parties.
        </p>
        <p>
          <strong className="font-bold"> Right to Correct. </strong>You have the
          right to request that we correct inaccurate personal information.
        </p>
        <p>
          <strong className="font-bold">Other Rights </strong>You can request
          certain information about our disclosure of personal information to
          third parties for their own direct marketing purposes during the
          preceding calendar year. This request is free and may be made once a
          year. You also have the right not to be discriminated against for
          exercising any of the rights listed above.
        </p>
        <p>
          <strong className="font-bold">
            Exercising Your California Privacy Rights.{" "}
          </strong>
          To request access to or deletion of your personal information, or to
          exercise any other data rights under California law, please contact us
          using the following method:
        </p>
        <p>
          <span className="text-decoration-line: underline">Email: </span> You
          may write to us to exercise your rights. Please include your full
          name, residential address, email, and phone number, along with why you
          are writing, so that we can process your request. Please email us at
          <Link
            to={"mailto:consumerdata@wearesouthwind.com"}
            className="text-sky-800"
          >
            consumerdata@wearesouthwind.com
          </Link>{" "}
        </p>
        <p>
          <span className="text-decoration-line: underline">Webform: </span> You
          may fill out a Webform to exercise your rights. Please include your
          full name, email, and phone number, along with why you are writing, so
          that we can process your request.
          <Link
            to={"mailto:consumerdata@wearesouthwind.com"}
            className="text-sky-800"
          >
            consumerdata@wearesouthwind.com
          </Link>{" "}
        </p>
        <p>
          <span className="text-decoration-line: underline">
            Toll-Free Telephone Number:
          </span>{" "}
          You may call us at (913) 382-7408 to exercise your rights. Please
          include your full name, residential address, email, and phone number,
          along with why you are calling, so that we can process your request.
          <Link
            to={"mailto:consumerdata@wearesouthwind.com"}
            className="text-sky-800"
          >
            consumerdata@wearesouthwind.com
          </Link>{" "}
        </p>
        <p>
          <strong className="font-bold">Response Timing.</strong>
          We aim to respond to a consumer request for access or deletion within
          45 days of receiving that request. If we require more time, we will
          inform you of the reason and extension period in writing.
        </p>
        <h3 id="7" className="text-2xl mb-1 font-bold my-3">
          7. THIRD-PARTY LINKS AND BEHAVIORAL TRACKING
        </h3>
        <p className="text-1xl mb-3 font-bold my-3">Third-Party Links.</p>
        <p>
          We may include links or offers to third-party products or services on
          the Website. These third-party websites have separate and independent
          privacy policies. We are not responsible or liable for the content or
          activities of these third-party websites.
        </p>
        <p className="text-1xl mb-3 font-bold my-3">
          Third-Party Information Gathering.
        </p>
        <p>
          SWVE includes content and ads from third parties that may perform user
          activity tracking other than that described herein. These other
          websites may place their own cookies on your computer, collect data,
          or solicit your personal information. For more information about each
          third-party content provider, cookies, and how to "opt-out", please
          refer to such third-party advertiser's privacy policy.
        </p>
        <h3 id="8" className="text-2xl mb-3 font-bold my-3">
          8. CHILDREN’S DATA
        </h3>
        <p>
          To use the Website, you must be 18 years or older and have the power
          to enter into a binding contract with us and not be barred from doing
          so under any applicable laws; or 13 years or older and have your
          parent or guardian's consent to the Agreement. The Website is not
          intended for children under the age of 13 and no person under the age
          of 13 may use the Website. If you use the Website, you are affirming
          that you are at least 13 years old.
        </p>
        <h3 id="9" className="text-2xl mb-3 font-bold my-3">
          9. CHANGES TO THIS PRIVACY POLICY
        </h3>
        <p>
          We may update this policy from time to time as the Website changes and
          privacy law evolves. If we update it, we will do so on the Website.
          When you use the Website, you are agreeing to the most recent terms of
          this policy.
        </p>
        <h3 className="text-2xl mb-3 font-bold my-3">10. CONTACT US</h3>
        <p id="10">
          If you have any questions or concerns about your privacy or anything
          in this policy, please contact us at{" "}
          <Link
            to={"mailto:consumerdata@wearesouthwind.com"}
            className="text-sky-800"
          >
            consumerdata@wearesouthwind.com
          </Link>
          .
        </p>
      </div>
      <div className="py-5 privacy_policy">
        <>
          <h2
            style={{
              marginTop: "18.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "115%",
              fontSize: 21,
              fontFamily: '"Arial",sans-serif',
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            <strong>Royal Blue Membership&nbsp;</strong>
          </h2>
          <h2
            style={{
              marginTop: "18.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "0cm",
              lineHeight: "115%",
              fontSize: 21,
              fontFamily: '"Arial",sans-serif',
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            <strong>Cancellation Policy and Terms &amp; Conditions</strong>
          </h2>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              Effective Date: 1/1/2024
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              Thank you for choosing the Royal Blue Membership with
              1-800-GOT-JUNK Membership ("we," "us," or "our"). Please read the
              following terms and conditions carefully before signing up for the
              Royal Blue Membership. By enrolling in this membership, you agree
              to be bound by the terms outlined below.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              1. Membership Term:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              The Royal Blue Membership requires a commitment term of 6 months
              from the date of enrollment.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              2. Early Cancellation Fee:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              If you cancel your Royal Blue Membership before the 6-month
              commitment term is completed, a fee of $78 will be automatically
              charged to the credit card on file.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              3. Communication Agreement:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              You agree to receive all communication regarding your Royal Blue
              Membership through email, phone calls, or SMS. It is your
              responsibility to keep your contact information up to date.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              4. Automatic Monthly Membership Charge:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              The membership monthly charge will be automatically withdrawn from
              the credit card on file on the 1st of every month. Please ensure
              that your payment information is accurate and up to date to avoid
              any disruptions in service.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              5. Contacting Customer Service:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              For any questions or concerns regarding your Royal Blue
              Membership, please contact our customer service team at
              membership@1800gotjunkmembership.com. We strive to respond
              promptly to all inquiries.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              6. Changes to Terms and Conditions:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              We reserve the right to modify, alter, or update these terms and
              conditions at any time. Any changes will be communicated to you
              through the contact information provided.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              7. Termination of Membership:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              We reserve the right to terminate your Royal Blue Membership in
              case of violating these terms and conditions, fraudulent activity,
              or any other reason deemed necessary by 1-800-GOT-JUNK Membership.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              8. Refund Policy:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              Membership fees are non-refundable; <strong>no refunds</strong>{" "}
              will be provided for any unused portion of the membership term.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              9. Governing Law:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              This agreement shall be governed by and construed in accordance
              with the laws of the jurisdiction in which you reside.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span
              style={{ fontSize: 16, lineHeight: "115%", fontFamily: "Roboto" }}
            >
              6. Mediation and Arbitration Clause:
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span
              style={{ fontSize: 16, lineHeight: "115%", fontFamily: "Roboto" }}
            >
              Any dispute, claim, or controversy arising out of or relating to
              this agreement, including the breach, termination, enforcement,
              interpretation, or validity thereof, shall be submitted to
              mediation in accordance with the rules of a mutually agreed-upon
              mediator. If mediation is unsuccessful, the dispute shall be
              resolved by binding arbitration in accordance with the rules of a
              mutually agreed-upon arbitrator. Both mediation and arbitration
              will occur in the governing state where the 1-800-GOT-JUNK
              Membership local provider office resides. The arbitrator’s
              decision shall be final and binding.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              By enrolling in the Royal Blue Membership, you acknowledge that
              you have read, understood, and agreed to these terms and
              conditions. If you disagree with the abovementioned terms, please
              refrain from enrolling in the Royal Blue Membership.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            <span style={{ fontSize: 16, lineHeight: "115%" }}>
              Thank you for choosing 1-800-GOT-JUNK Membership!
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            &nbsp;
          </p>
          <p
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              lineHeight: "115%",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
            }}
          >
            &nbsp;
          </p>
        </>
      </div>
    </div>
  );
};

export default TermsConditions;
