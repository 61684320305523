import React from "react";
import Select from "react-select";

const SurchargeItem = ({
  onChange,
  surchargePrice,
  data,
  setMultiSelectValue,
  multiSelectValueQuantity,
  multiSelectValue,
  setQuantity,
}) => {
  console.log(multiSelectValueQuantity);
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div>
          <label className="text-light">Select surcharge</label>

          <Select
            isSearchable={false}
            isMulti
            name="surchargePrice"
            setValue={multiSelectValue}
            onChange={onChange}
            options={data.surchargeItem}
          />
        </div>
      </div>

      {multiSelectValueQuantity.length !== 0 ? (
        <div className="col-12">
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "80%" }}>Name</th>
                  <th style={{ width: "10%" }}>Price</th>
                  <th style={{ width: "10%" }}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {multiSelectValue.map((item, index) => (
                  <tr>
                    <td className="align-middle">{item.label}</td>
                    <td className="align-middle">${item.value}</td>
                    <td className="align-middle">
                      <input
                        max-maxLength={2}
                        className="form-control"
                        type="number"
                        onChange={(e) => {
                          const quantity = Number(e.target.value);
                          setQuantity(quantity, item);
                          //   onChange(item, quantity);
                        }}
                        value={
                          //   multiSelectValueQuantity.length === index + 1
                          //     ? multiSelectValueQuantity[index]?.quantity
                          //     : 1
                          multiSelectValueQuantity[index]?.quantity
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SurchargeItem;
