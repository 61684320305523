import React, { useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import logo from "../../assets/image/logo.png";

const Header = () => {
  const navigate = useNavigate();
  const { LogoutApi } = useApiAxios();
  const { isLoading, isAuthorized } = useContext(Context);
  return (
    <div className="container-fluid">
      <div className="container  ">
        <div className="row align-items-center py-3 border-bottom mb-4">
          <Link to="/" className="col-6">
            <img
              src={logo}
              alt="1-800-GOT-JUNK?"
              title="1-800-GOT-JUNK?"
              className="logo"
            />
          </Link>
          <div className="col-6 text-end">
            {isAuthorized ? (
              <button className="btn btn_dark" onClick={() => LogoutApi()}>
                <i className="fa fa-power-off"></i> Logout
              </button>
            ) : (
              <button
                className="btn btn_dark"
                onClick={() => navigate("/login")}
              >
                <i className="fa fa-sign-in"></i> Login
              </button>
            )}
          </div>
        </div>
        <div style={{ minHeight: "80vh" }}>
          <Outlet />
        </div>
      </div>
      <div className="row border-top light_gradient py-3 footer bottom">
        <div className="row">
          <div className="col-12 text-center">
            <Link to="terms&Service" className="textDark">
              Terms and Conditions
            </Link>
            <div>
              <strong>(913) 444-9386</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
