import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear info in session storage
    sessionStorage.removeItem("data");
  }, []);

  return (
    <div className="row">
      <div className="col-lg-9 col-md-10 m-auto">
        <div className="dark_gradient textLight p-3 p-lg-5 text-center">
          <h1 className="mb-4">
            Congratulations and a warm welcome to the
            <br /> <strong>1-800-GOT-JUNK? Membership! </strong>
          </h1>
          {/* <p>
            We’re thrilled to have you on board, and we appreciate your decision
            to make space for what matters most.
          </p> */}
          <h5 className="mb-4">
            Please check your email for important instructions on completing
            your portal registration
          </h5>
          <p className="fw-bold">
            You've taken the first step towards a clutter-free life, and we're
            here to make the journey seamless for you. As a new member, here's
            what you can look forward to:
          </p>
          <hr className="hr" />
          <p>
            <strong>- Priority Scheduling:</strong> Enjoy the convenience of
            priority scheduling for your junk removal appointments.
          </p>
          <p>
            <strong>- Exclusive Member Discounts:</strong> Access special
            discounts and promotions tailored just for you.
          </p>
          <p>
            <strong>- Personalized Junk Removal Plans:</strong> Our team is
            ready to create a customized junk removal plan to fit your needs and
            schedule.
          </p>
          <p>
            <strong>- Eco-Friendly Solutions:</strong> Feel good about your
            choice – we prioritize environmentally responsible disposal methods.
          </p>
          <h4>Any Questions?</h4>
          <p>
            Our customer support team is here to assist you at any step of the
            way. Should you have any questions or need further assistance,
            please don't hesitate to contact us at <br />
            <span className="text-white">(913) 444-9386</span> or{" "}
            <span className="text-white">
              <a
                className="link_white"
                href="mail:hello@1800gotjunkmembership.com"
              >
                hello@1800gotjunkmembership.com
              </a>
              .
            </span>
          </p>
          {/* <p>
            We're excited to help you reclaim your space and simplify your life.
            Get ready to experience the joy of a clutter-free environment!
          </p> */}
          <p className="mb-0">
            <button onClick={() => navigate("/survey")} className="forward_btn mb-2">
              Survey <i className="fa fa-angle-right"></i>
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Success;
