import React from "react";
import Select from "react-select";

const BedLoad = ({ data, onChange }) => {
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div>
          <label className="text-light">Select BedLoad</label>
          <Select
            isMulti
            isSearchable={false}
            name="surchargePrice"
            onChange={onChange}
            options={data.map(item => ({ label: item.label, value: item.value }))}
          />
        </div>
      </div>
    </div>
  );
};

export default BedLoad;
