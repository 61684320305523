import React from "react";

const ScheduleAppointment = () => {
  return (
    <div>
      <h2>Schedule Appointment</h2>
    </div>
  );
};

export default ScheduleAppointment;
