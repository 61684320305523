import React from "react";

const Account = () => {
  return (
    <div>
      <form>
        <h2 className="mb-4">Personal Details</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Mobile
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <button type="submit" className="btn btn_dark mt-3">
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Account;
