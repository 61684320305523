import { Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Context } from "./../../context/Context";
import useApiAxios from "./../../api/useApiAxios";
import Loader from "./../loader/Loader";
import Home from "../home/Home";
import Login from "../login/Login";
import SnackbarComponent from "../SnackbarComponent";
import SetPassword from "../setPassword/SetPassword";
import TermsConditions from "../termsConditions/TermsConditions";
import Dashboard from "../dashboard/Dashboard";
import ScheduleAppointment from "../scheduleAppointment/ScheduleAppointment";
import Account from "../account/Account";
import Payment from "../payment/Payment";
import Security from "../security/Security";
import Success from "../successTip/Success";
import ForgetPassword from "../forgetPassword/ForgetPassword";
import Header from "../header/Header";
import CardSetup from "../../pages/CardSetup";
import Gratuity from "../../pages/Gratuity";
import Membership from "../../pages/Membership";
import Upfront from "../../pages/Upfront";
import ResetPassword from "../resetPassword/ResetPassword";
import Survey from "../survey/Survey";

const Routing = () => {
  const { getUserInfoApi, sessionStorageCheck } = useApiAxios();
  const { isLoading, openErrorSnackbar } = useContext(Context);
  const [firstLoading, setFirstLoading] = useState(false);

  useEffect(() => {
    if (!sessionStorageCheck()) {
      openErrorSnackbar(
        "Site will not work in this browser. Session storage is not enabled."
      );
      throw new Error("Session storage is not supported in this browser.");
    }

    const fun = async () => {
      setFirstLoading(false);
      await getUserInfoApi();
      setFirstLoading(true);
    };
    fun();
  }, []);

  return (
    <>
      <Loader open={isLoading} />
      <SnackbarComponent />
      {firstLoading && (
        <>
          <Routes>
            <Route path="/" element={<Header />}>
              <Route path="/" element={<Home />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/success" element={<Success />}></Route>
              <Route path="/survey" element={<Survey />}></Route>
              <Route path="/setup" element={<CardSetup />} />
              <Route path="/gratuity" element={<Gratuity />} />
              <Route path="/membership" element={<Membership />} />
              <Route path="/upfront" element={<Upfront />} />

              <Route path="dashboard" element={<Dashboard />}>
                <Route index element={<ScheduleAppointment />} />
                <Route path="payment" element={<Payment />} />
                <Route path="account" element={<Account />} />
                <Route path="Security" element={<Security />} />
              </Route>
              <Route path="/set-password" element={<SetPassword />}></Route>
              <Route
                path="/forget-password"
                element={<ForgetPassword />}
              ></Route>
              <Route
                path="/reset-password/:uid/:token"
                element={<ResetPassword />}
              ></Route>
              <Route
                path="/terms&Service"
                element={<TermsConditions />}
              ></Route>
              <Route path="/*" element={<Navigate replace to="/" />} />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};

export default Routing;
