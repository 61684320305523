import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import queryString from "query-string";
import useApiAxios from "../../api/useApiAxios";

const SetPassword = () => {
  const { setPasswordApi } = useApiAxios();
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const [password1, password2] = watch(["password1", "password2"]);

  // Used to check if passwords match
  useEffect(() => {
    if (!(password1 === password2) && password2?.length !== 0) {
      setError("password2", {
        type: "custom",
        message: "Passwords do not match.",
      });
    }
    if (password1 === password2) {
      clearErrors("password2");
    }
  }, [password2, password1, setError]);

  const onSubmit = (data) => {
    // Handle signup logic using the form data
    const { stripe_id, customer_email } = queryString.parse(
      window.location.search
    );

    setPasswordApi({
      ...data,
      username: customer_email,
      email: customer_email,
      stripeId: stripe_id,
    });
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 m-auto">
            <div className="bg-white shadow">
              <div className="row align-items-center">
                <div className="col-md-6 align-self-stretch">
                  <div className="form-right h-100 bg-primary text-white text-center pt-5">
                    <h2 className="fs-1 pt-0 pt-md-5 mt-0 mt-md-5 mb-5">
                      Set<br></br> Password
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 p-5">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label className="fw-bold">Enter Password</label>
                        <input
                          placeholder="Enter password"
                          type="password"
                          className={`form-control ${
                            errors.password1 ? "is-invalid" : ""
                          }`}
                          id="password"
                          {...register("password1", {
                            required: "Password is required",
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?-])[A-Za-z\d#$@!%&*?-]{8,30}$/,
                              message:
                                "Minimum 8 characters, both uppercase & lowercase, a special character -,#,$,@,!,%,&,*,? and at least one number.",
                            },
                          })}
                        />
                        {errors.password1 && (
                          <div className="invalid-feedback">
                            {errors.password1.message}
                          </div>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <label className="fw-bold">Confirm Password</label>
                        <input
                          placeholder="Enter confirm password"
                          type="password"
                          className={`form-control ${
                            errors.password2 ? "is-invalid" : ""
                          }`}
                          id="confirm-password"
                          {...register("password2", {
                            required: "Confirm password is required",
                          })}
                        />
                        {errors.password2 && (
                          <div className="invalid-feedback">
                            {errors.password2.message}
                          </div>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <button type="submit" className="btn btn_dark px-4">
                          Set Password
                        </button>
                      </div>
                      <div className="col-12">
                        <div className="form-group mx-auto d-flex align-items-center my-4">
                          <div className="border-bottom w-100 ml-5" />
                          <span className="px-2 small text-muted font-weight-bold text-muted">
                            OR
                          </span>
                          <div className="border-bottom w-100 mr-5" />
                        </div>
                      </div>
                      <div className="col-12">
                        <p className="text-muted font-weight-bold mb-0">
                          Already Registered?{" "}
                          <Link to="/login" className="textDark ml-2">
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
