import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import { useNavigate } from "react-router";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import useApiAxios from "./../../api/useApiAxios";
import StripeLoadingMsg from "../../components/StripeLoadMsg";

const stripePromise = await loadStripe(process.env.REACT_APP_STRIPEId);

const CardSetup = () => {
  const { cardSetup, getSessionStorageData } = useApiAxios();
  const { stripe_id } = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const goToOneTimePayment = getSessionStorageData().one_time_purchase;

  const [clientSecret, setClientSecret] = useState("");

  // Go to upfront for one-time payment or membership payment
  const handlePath = (id) => {
    let path = ``;
    path = goToOneTimePayment
      ? `/upfront?stripe_id=${id}`
      : `/membership?stripe_id=${id}`;
    return path;
  };

  const handleComplete = async () => {
    console.log("Card Setup completed");

    const path = handlePath(stripe_id);
    navigate(path, { replace: true });
  };
  const fetchCS = async () => {
    const res = await cardSetup(stripe_id);
    setClientSecret(res?.data?.checkout_client_secret);
  };
  useEffect(() => {
    fetchCS(); // eslint-disable-next-line
  }, []);

  return (
    <div id="card-setup">
      {clientSecret ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret, onComplete: handleComplete }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <StripeLoadingMsg />
      )}
    </div>
  );
};

export default CardSetup;
