import queryString from "query-string";
import React from "react";
import useApiAxios from "../../api/useApiAxios";
import { useForm } from "react-hook-form";
import { Link, useParams, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const { setRestPasswordApi } = useApiAxios();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let { uid, token } = useParams();

  const onSubmit = (data) => {
    // Handle signup logic using the form data

    setRestPasswordApi({
      ...data,
      uid,
      token,
    });
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 m-auto">
            <div className="bg-white shadow">
              <div className="row align-items-center">
                <div className="col-md-6 align-self-stretch">
                  <div className="form-right h-100 bg-primary text-white text-center pt-5">
                    <h2 className="fs-1 pt-0 pt-md-5 mt-0 mt-md-5 mb-5">
                      Reset<br></br> Password
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 p-5">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label className="fw-bold">Enter Password</label>
                        <input
                          placeholder="Enter password"
                          type="password"
                          className={`form-control ${
                            errors.new_password1 ? "is-invalid" : ""
                          }`}
                          id="new_password1"
                          {...register("new_password1", {
                            required: "Password is required",
                          })}
                        />
                        {errors.new_password1 && (
                          <div className="invalid-feedback">
                            {errors.new_password1.message}
                          </div>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <label className="fw-bold">Confirm Password</label>
                        <input
                          placeholder="Enter confirm password"
                          type="password"
                          className={`form-control ${
                            errors.new_password2 ? "is-invalid" : ""
                          }`}
                          id="new_password2"
                          {...register("new_password2", {
                            required: "Password is required",
                          })}
                        />
                        {errors.new_password2 && (
                          <div className="invalid-feedback">
                            {errors.new_password2.message}
                          </div>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <button type="submit" className="btn btn_dark px-4">
                          Set Password
                        </button>
                      </div>
                      <div className="col-12">
                        <div className="form-group mx-auto d-flex align-items-center my-4">
                          <div className="border-bottom w-100 ml-5" />
                          <span className="px-2 small text-muted font-weight-bold text-muted">
                            OR
                          </span>
                          <div className="border-bottom w-100 mr-5" />
                        </div>
                      </div>
                      <div className="col-12">
                        <p className="text-muted font-weight-bold mb-0">
                          <Link to="/login" className="textDark ml-2">
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
