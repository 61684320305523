import React from "react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
const ForgetPassword = () => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { resetPasswordApi } = useApiAxios();
  const [error, setError] = useState({ className: "hidden", message: "" });
  const [username, password] = watch(["username", "password"]);
  useEffect(() => {
    setError({ className: "hidden", message: "" });
  }, [username, password]);
  console.log(errors);

  return (
    <div className="login-page mt-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 m-auto">
            <div className="bg-white shadow">
              <div className="row align-items-center">
                <div className="col-md-6 align-self-stretch">
                  <div className="form-right h-100 bg-primary text-white text-center pt-5">
                    <h2 className="fs-1 pt-0  mt-0 mt-md-5 mb-5">
                      Forget Password
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 p-5">
                  <form
                    onSubmit={handleSubmit(async (data) => {
                      const response = await resetPasswordApi(data);
                    })}
                  >
                    <div className="row">
                      <div className="col-12 mb-2">
                        <label className="fw-bold">Email address</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email"
                          id="exampleInputEmail1"
                          {...register("username", {
                            required: "Email is required.",
                            pattern: {
                              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                              message: "Enter a valid email address",
                            },
                            maxLength: {
                              value: 64,
                              message: "Enter a valid email address",
                            },
                            minLength: {
                              value: 5,
                              message: "Enter a valid email address",
                            },
                          })}
                          aria-describedby="emailHelp"
                        />
                        {errors.username && (
                          <div className="invalid-feedback">
                            {errors.username.message}
                          </div>
                        )}
                      </div>
                      <div className="col-12 mb-1">
                        <div style={{ textAlign: "right" }} className="col-12">
                          <Link to="/login">Login</Link>
                        </div>
                      </div>

                      <div className="col-12">
                        <button type="submit" className="btn btn_dark px-4">
                          Get reset password link
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
