import React, { useEffect, useState } from "react";
import data from "./../../data/data.json";
import FormForMemberShip from "../form/FormForMemberShip";
import SurchargeItem from "../surchargeItem/SurchargeItem";
import Select from "react-select";
import BedLoad from "../bedLoad/BedLoad";

const SingleItemRemoval = ({ selectedRegion }) => {
  const [regionData, setRegionData] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [bedLoadPrice, setBedLoadPrice] = useState(0);

  const [membershipStatus, setMembershipStatus] = useState(false);
  const [surchargePrice, setSurchargePrice] = useState(0);
  const [multiSelectValue, setMultiSelectValue] = useState([]);
  const [multiSelectValueQuantity, setMultiSelectValueQuantity] = useState([]);
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState(0);

  useEffect(() => {
    console.log("region maddness")
    console.log(selectedRegion)
    if (selectedRegion && data.regions[selectedRegion]) {
      setRegionData(data.regions[selectedRegion]);
    }
  }, [selectedRegion]);


  const onSelectOnchangeBedLoad = async (e) => {
    let value = 0;
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (typeof element.value !== "number") {
        element.value = Number(element.value.split("-")[1]);
      }
      value = value + element.value;
    }
    setPriceBeforeDiscount(value + price + surchargePrice);
    setBedLoadPrice(value);
    if (membershipStatus === 1) {
      setTotalPrice(value + price + surchargePrice - 78.0);
    } else {
      setTotalPrice(value + price + surchargePrice);
    }
  };
  const bulkPriceOnChange = (e) => {
    let value = 0;
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (typeof element.value !== "number") {
        element.value = Number(element.value.split("-")[1]);
      }
      value = value + element.value;
    }
    setPrice(value);
    setPriceBeforeDiscount(value);
    if (membershipStatus === 1) {
      setTotalPrice(value - 78.0);
    } else {
      setTotalPrice(value);
    }
    setBedLoadPrice(0);
    setSurchargePrice(0);
    setMultiSelectValue([]);
  };
  
  const onSelectOnchange = async (e) => {
    // console.log(typeof quantity);
    const newE = [];

    const aa = [];

    console.log("e", e);
    let value = 0;
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      console.log(element);

      if (typeof element.value !== "number") {
        element.value = Number(element.value.split("-")[1]);
      }
      newE.push(element);
      if (multiSelectValue.some((l) => l.label === e.label)) {
        const dd = multiSelectValue.find(
          (element) => e.label === element.label
        );
        aa.push({ ...element, quantity: dd.quantity });
        value = value + element.value * dd.quantity;
      } else {
        aa.push({ ...element, quantity: 1 });
        value = value + element.value;
      }
    }
    setMultiSelectValue(newE);
    setMultiSelectValueQuantity(aa);
    setPriceBeforeDiscount(value + bedLoadPrice + price);
    if (membershipStatus === 1) {
      setTotalPrice(value + bedLoadPrice + price - 78.0);
    } else {
      setTotalPrice(value + bedLoadPrice + price);
    }
    setSurchargePrice(value);
  };
  const setQuantity = (q, item) => {
    let value = 0;
    const nextShapes = multiSelectValueQuantity.map((shape) => {
      if (shape.label === item.label) {
        console.log(shape.label, "aa", item.label);
        shape.quantity = q;
        value = value + shape.value * q;
        return shape;
      } else {
        value = value + shape.value * shape.quantity;
        // Return a new circle 50px below
        return shape;
      }
    });
    setMultiSelectValueQuantity(nextShapes);
    setPriceBeforeDiscount(value + bedLoadPrice + price);

    if (membershipStatus === 1) {
      setTotalPrice(value + bedLoadPrice + price - 78.0);
    } else {
      setTotalPrice(value + bedLoadPrice + price);
    }
    setSurchargePrice(value);
  };
  return (
    <div className="homeBox rounded p-4">
      <h2 className="colorWhite mb-3 text-center">Single item removal</h2>
      <div className="row">
        <div className="col-12">
          <label className="text-light">Select any item</label>
          <Select
            isSearchable={false}
            isMulti
            name="surchargePrice"
            // setValue={multiSelectValue}
            onChange={bulkPriceOnChange}
            options={regionData.singleItem || []}
          />
        </div>
      </div>
      {price !== 0 ? (
        <>
          <SurchargeItem
            onChange={onSelectOnchange}
            surchargePrice={surchargePrice}
            data={regionData}
            multiSelectValueQuantity={multiSelectValueQuantity}
            multiSelectValue={multiSelectValue}
            setMultiSelectValue={setMultiSelectValue}
            setQuantity={setQuantity}
          />
          <BedLoad
  data={regionData.bedLoad || []} // Ensures BedLoad receives the correct data
  onChange={onSelectOnchangeBedLoad}
/>
        </>
      ) : (
        <></>
      )}
      {price !== 0 ? (
        <div className="row">
          <div className="col-6 text-center align-self-stretch">
            <div
              onClick={() => {
                setMembershipStatus(0);
                setTotalPrice(bedLoadPrice + surchargePrice + price);
              }}
              className={`card p-2 p-sm-3 h-100 border-5 ${
                membershipStatus === 0 ? "border border-warning border-5" : ""
              } `}
            >
              <p className="fw-bold">Pay Full Price</p>
              {/* <p className="lh-1">Price</p> */}
              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  type="text"
                  value={priceBeforeDiscount}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setPriceBeforeDiscount(e.target.value);
                  }}
                  className="form-control text-center fs-2"
                  aria-label="Amount (to the nearest dollar)"
                ></input>
              </div>
            </div>
          </div>
          <div className="col-6 text-center align-self-stretch">
            <div
              className={`card p-2 p-sm-3 h-100 border-5 ${
                membershipStatus === 1 ? "border border-warning border-5" : ""
              } `}
              onClick={() => {
                setMembershipStatus(1);
                setTotalPrice(bedLoadPrice + surchargePrice + price - 78.0);
              }}
            >
              <p className="fw-bold">Select and Save</p>
              {/* <p className="lh-1"></p> */}
              <h2 className="textDark">
                <del className="fs-6 text-dark">${priceBeforeDiscount}</del>{" "}
                {priceBeforeDiscount - 78.0 > 0
                  ? `$${priceBeforeDiscount - 78.0}`
                  : "$0"}
              </h2>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {price !== 0 ? (
        <div className="col-12 mt-3">
          {membershipStatus === 1 ? (
            <FormForMemberShip
              totalPrice={priceBeforeDiscount - 78.0}
              priceBeforeDiscount={priceBeforeDiscount}
            />
          ) : (
            <button
              className="btn btn-lg w-100 btn-warning"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.cardflight.swipesimple&hl=en&gl=US&pli=1",
                  "_blank"
                )
              }
            >
              Make Payment
            </button>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SingleItemRemoval;