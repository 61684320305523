import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import { useNavigate } from "react-router";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import useApiAxios from "../../api/useApiAxios";
import StripeLoadingMsg from "../../components/StripeLoadMsg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPEId);

/**  Stripe Embedded form for Memberships */
const Membership = () => {
  const { getMembershipSecret, getSessionStorageData, completeOnboardingApi } =
    useApiAxios();
  const { stripe_id } = queryString.parse(window.location.search);
  const salesforce_opp_id = getSessionStorageData()?.salesforce_opp_id;
  const navigate = useNavigate();

  const [displayOptions, setDisplayOptions] = useState(true);
  const [membershipType, setMembershipType] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const handleComplete = () => {
    console.log("Membership was completed");
    completeOnboardingApi(stripe_id);

    const path = `/gratuity?stripe_id=${stripe_id}`;
    navigate(path, { replace: true });
  };

  const fetchSecret = async (annual) => {
    // annual is either true or false depending if membership is yearly
    const res = await getMembershipSecret(stripe_id, salesforce_opp_id, annual);
    setClientSecret(res?.data?.checkout_client_secret);
    setDisplayOptions(false);
  };
  return (
    <>
      {displayOptions ? (
        <div>
          <div className="homeBox rounded p-4 mb-3">
            <h2 className="colorWhite text-center mb-3">Membership Type</h2>
            <div className="row g-4">
              <div className="col-12 text-center">
                <div
                  onMouseOver={() => setMembershipType("monthly")}
                  onMouseOut={() => setMembershipType("")}
                  onClick={() => {
                    setMembershipType("monthly");
                    fetchSecret(false);
                  }}
                  className={`card p-2 p-sm-3 h-100 border-5 ${
                    membershipType === "monthly"
                      ? "border border-warning border-5"
                      : ""
                  } `}
                >
                  <p className="fw-bold">Monthly</p>
                  <h2 className="textDark">$13</h2>
                  <span className="fs-6 text-dark">per month</span>
                </div>
              </div>
              <div className="col-12 text-center">
                <div
                  onMouseOver={() => setMembershipType("yearly")}
                  onMouseOut={() => setMembershipType("")}
                  onClick={() => {
                    setMembershipType("yearly");
                    fetchSecret(true);
                  }}
                  className={`card p-2 p-sm-3 h-100 border-5 ${
                    membershipType === "yearly"
                      ? "border border-warning border-5"
                      : ""
                  } `}
                >
                  <p className="fw-bold">Yearly</p>
                  <h2 className="textDark">$156</h2>
                  <span className="fs-6 text-dark">per year</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="membership">
          {clientSecret ? (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret, onComplete: handleComplete }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          ) : (
            <StripeLoadingMsg />
          )}
        </div>
      )}
    </>
  );
};

export default Membership;
