import React, { useContext, useState } from "react";
import logo from "../../assets/image/logo.png";
import { Context } from "./../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const { LogoutApi } = useApiAxios();
  const { pathname } = useLocation();
  const { isLoading, isAuthorized } = useContext(Context);
  return (
    <div className="container">
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <a
            className={
              "  nav-link  " + (pathname === "/dashboard" ? `active` : "")
            }
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard");
            }}
            href="#link3"
          >
            Schedule Appointment
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              "  nav-link  " +
              (pathname === "/dashboard/payment" ? `active` : "")
            }
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/payment");
            }}
            href="#link3"
          >
            Payment
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              "  nav-link  " +
              (pathname === "/dashboard/account" ? `active` : "")
            }
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/account");
            }}
            href="#link3"
          >
            Account
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              "  nav-link  " +
              (pathname === "/dashboard/security" ? `active` : "")
            }
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/security");
            }}
            href="#link3"
          >
            Security
          </a>
        </li>
      </ul>
      <Outlet />
    </div>
  );
};

export default Dashboard;
