import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import useApiAxios from "./../../api/useApiAxios";
import star from "../../assets/image/star.png";
import StripeLoadingMsg from "../../components/StripeLoadMsg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPEId);

// On load will display tip options and onClick it will display the embedded form
const Gratuity = () => {
  const { getSessionStorageData, getGratuitySecret } = useApiAxios();
  const { stripe_id } = queryString.parse(window.location.search);
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");
  const [displayOptions, setDisplayOptions] = useState(true);
  const [price, setPrice] = useState(0);

  // Determine tip amount if coming from membership or one-time payment
  const priceAt =
    getSessionStorageData()?.one_time_purchase === true
      ? getSessionStorageData()?.price
      : getSessionStorageData()?.price_before_discount;
  const salesforce_opp_id = getSessionStorageData()?.salesforce_opp_id;

  const handleComplete = () => {
    console.log("Gratuity payment completed");

    navigate("/success", { replace: true });
  };
  const addTip = async (tip) => {
    try {
      const res = await getGratuitySecret(stripe_id, tip, salesforce_opp_id);
      setClientSecret(res.data?.checkout_client_secret);

      // display form by removing tip options
      setDisplayOptions(false);
    } catch (error) {
      console.log(error);
    }
  };
  return displayOptions ? (
    <>
      <div className="row" style={{ minHeight: "70vh" }}>
        <div className="col-lg-9 col-md-10 m-auto d-flex align-items-center">
          <div className="dark_gradient textLight p-3 p-lg-5 text-center">
            <h1 className="mb-4">Welcome To Your Royal Blue Membership!</h1>

            <h5 className="mb-4">
              You've taken the first step towards a clutter-free life, and we're
              here to make your journey seamless! We hope we've delivered on our
              promise of a 5-Star customer experience!
            </h5>
            <h5 className="mb-4">
              Please check your email for important instructions on completing
              your member portal registration!
            </h5>
            <p className="mb-4">
              <img src={star} width={30} alt="" />
              <img src={star} width={30} alt="" />
              <img src={star} width={30} alt="" />
              <img src={star} width={30} alt="" />
              <img src={star} width={30} alt="" />
            </p>
            <div className=" d-flex justify-content-center  row row-cols-auto">
              <button
                onClick={() => navigate("/success")}
                className="back_btn me-0 me-sm-2 tipBtn mt-2"
              >
                No Tip
              </button>

              <button
                onClick={() =>
                  addTip(
                    (Math.round(Number(priceAt) * 0.1 * 100) / 100).toFixed(2)
                  )
                }
                className="back_btn tipBtn me-sm-2 mt-2"
              >
                <span style={{ display: "block" }}>
                  <h4>10%</h4>
                </span>
                <span style={{ display: "block" }}>
                  <h4>
                    ($
                    {(Math.round(Number(priceAt) * 0.1 * 100) / 100).toFixed(2)}
                    )
                  </h4>
                </span>
              </button>
              <button
                onClick={() =>
                  addTip(
                    (Math.round(Number(priceAt) * 0.15 * 100) / 100).toFixed(2)
                  )
                }
                className="back_btn tipBtn me-sm-2 mt-2"
              >
                <span style={{ display: "block" }}>
                  <h4>15%</h4>
                </span>
                <span style={{ display: "block" }}>
                  <h4>
                    ($
                    {(Math.round(Number(priceAt) * 0.15 * 100) / 100).toFixed(
                      2
                    )}
                    )
                  </h4>
                </span>
              </button>
              <button
                onClick={() =>
                  addTip(
                    (Math.round(Number(priceAt) * 0.2 * 100) / 100).toFixed(2)
                  )
                }
                className="back_btn tipBtn me-sm-2 mt-2"
              >
                <span style={{ display: "block" }}>
                  <h4>20%</h4>
                </span>
                <span style={{ display: "block" }}>
                  <h4>
                    ($
                    {(Math.round(Number(priceAt) * 0.2 * 100) / 100).toFixed(2)}
                    )
                  </h4>
                </span>
              </button>
              <div
                onClick={() => addTip(price)}
                className="back_btn tipBtn me-sm-2 mt-2"
              >
                <span style={{ display: "block" }}>
                  <h4>Custom</h4>
                </span>
                <span style={{ display: "block" }}>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      maxLength={6}
                      defaultValue={`0.00`}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        setPrice(Number(e.target.value));
                      }}
                      className="form-control text-center fs-2"
                      aria-label="Amount (to the nearest dollar)"
                    ></input>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div id="gratuity">
      {clientSecret ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret, onComplete: handleComplete }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <StripeLoadingMsg />
      )}
    </div>
  );
};

export default Gratuity;
