import React from "react";

const Security = () => {
  return (
    <div>
      <form>
        <h2 className="mb-4">Update Password</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Current Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              New Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Confirm New Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <button type="submit" className="btn btn_dark">
              Update Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Security;
