import React, { useState, useEffect } from "react";
import initialData from "./../../data/data.json";
import FormForMemberShip from "../form/FormForMemberShip";
import SurchargeItem from "../surchargeItem/SurchargeItem";
import Select from "react-select";
import BedLoad from "../bedLoad/BedLoad";

const BulkVolumeRemoval = ({selectedRegion}) => {
  // const [selectedRegion, setSelectedRegion] = useState("");
  const [regionData, setRegionData] = useState({}); // Renamed state variable to regionData
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [price2, setPrice2] = useState(0);
  const [membershipStatus, setMembershipStatus] = useState(0);
  const [surchargePrice, setSurchargePrice] = useState(0);
  const [bedLoadPrice, setBedLoadPrice] = useState(0);
  const [multiSelectValue, setMultiSelectValue] = useState([]);
  const [multiSelectValueQuantity, setMultiSelectValueQuantity] = useState([]);
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState(0);

  useEffect(() => {
    if (selectedRegion) {
      // Use initialData to get the region specific data
      setRegionData(initialData.regions[selectedRegion]);
    }
  }, [selectedRegion]);

  // const regionOptions = [
  //   { value: 'Kansas City', label: 'Kansas City' },
  //   { value: 'Denver/Boulder', label: 'Denver/Boulder' },
  //   { value: 'Indianapolis', label: 'Indianapolis' }
  //   // Add more regions as needed
  // ];

  const onSelectOnchange = async (e) => {
    const newE = [];
    const aa = [];
    let value = 0;
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      console.log(element);

      if (typeof element.value !== "number") {
        element.value = Number(element.value.split("-")[1]);
      }
      newE.push(element);
      if (multiSelectValue.some((l) => l.label === e.label)) {
        const dd = multiSelectValue.find(
          (element) => e.label === element.label
        );
        aa.push({ ...element, quantity: dd.quantity });
        value = value + element.value * dd.quantity;
      } else {
        aa.push({ ...element, quantity: 1 });
        value = value + element.value;
      }
    }
    setMultiSelectValue(newE);
    setMultiSelectValueQuantity(aa);
    setPriceBeforeDiscount(value + bedLoadPrice + price + price2);
    if (membershipStatus === 1) {
      setTotalPrice(value + price + bedLoadPrice - 78.0 + price2);
    } else {
      setTotalPrice(value + bedLoadPrice + price + price2);
    }
    setSurchargePrice(value);
  };

  const onSelectOnchangeBedLoad = async (e) => {
    let value = 0;
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (typeof element.value !== "number") {
        element.value = Number(element.value.split("-")[1]);
      }
      value = value + element.value;
    }
    setBedLoadPrice(value);
    setPriceBeforeDiscount(value + price + surchargePrice + price2);
    if (membershipStatus === 1) {
      setTotalPrice(value + price + surchargePrice - 78.0 + price2);
    } else {
      setTotalPrice(value + price + surchargePrice + price2);
    }
  };

  const setQuantity = (q, item) => {
    let value = 0;
    const nextShapes = multiSelectValueQuantity.map((shape) => {
      if (shape.label === item.label) {
        console.log(shape.label, "aa", item.label);
        shape.quantity = q;
        value = value + shape.value * q;
        return shape;
      } else {
        value = value + shape.value * shape.quantity;
        // Return a new circle 50px below
        return shape;
      }
    });
    setMultiSelectValueQuantity(nextShapes);
    setPriceBeforeDiscount(value + bedLoadPrice + price + price2);
    if (membershipStatus === 1) {
      setTotalPrice(value + bedLoadPrice + price2 + price - 78.0);
    } else {
      setTotalPrice(value + bedLoadPrice + price + price2);
    }
    setSurchargePrice(value);
  };
  const bulkPriceOnChange = (e) => {
    let value = 0;
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      value = value + element.value;
    }
    setPrice(value);
    setPriceBeforeDiscount(value);
    if (membershipStatus === 1) {
      setTotalPrice(value - 78.0);
    } else {
      setTotalPrice(value);
    }
    setBedLoadPrice(0);
    setSurchargePrice(0);
    setPrice2(0);
    setMultiSelectValue([]);
  };
  const selectAnyItemOnChange = (e) => {
    let value = 0;
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (typeof element.value !== "number") {
        element.value = Number(element.value.split("-")[1]);
      }
      value = value + element.value;
    }
    setPrice2(value);
    setPriceBeforeDiscount(value + price + bedLoadPrice + surchargePrice);
    if (membershipStatus === 1) {
      setTotalPrice(value - 78.0 + price + bedLoadPrice + surchargePrice);
    } else {
      setTotalPrice(value + price + bedLoadPrice + surchargePrice);
    }
  };
  return (
    <div className="homeBox rounded p-4 mb-3">
      <h2 className="colorWhite mb-3 text-center">Bulk volume removal</h2>
      {/* <div className="col-12 mb-3">
        <Select
          options={regionOptions}
          onChange={(selectedOption) => setSelectedRegion(selectedOption.value)}
          placeholder="Select a Region"
          className="basic-single"
          classNamePrefix="select"
        />
      </div> */}
      <div className="col-12">
        <div className="row">
          <div className="col-12 mb-3">
            <div>
              <label className="text-light">Select any volume</label>
              <Select
                isSearchable={false}
                isMulti
                name="surchargePrice"
                onChange={bulkPriceOnChange}
                options={regionData.bulkVolumeRemoval || []}
              />
            </div>
          </div>
        </div>
      </div>
  
      {price !== 0 && (
        <>
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-3">
                <div>
                  <label className="text-light">Select any item</label>
                  <Select
                    isSearchable={false}
                    isMulti
                    name="surchargePrice"
                    onChange={selectAnyItemOnChange}
                    options={regionData.singleItem || []}
                  />
                </div>
              </div>
            </div>
          </div>
          <SurchargeItem
            onChange={onSelectOnchange}
            surchargePrice={surchargePrice}
            data={regionData}
            multiSelectValueQuantity={multiSelectValueQuantity}
            multiSelectValue={multiSelectValue}
            setMultiSelectValue={setMultiSelectValue}
            setQuantity={setQuantity}
          />
          {regionData.bedLoad && (
            <BedLoad data={regionData.bedLoad} onChange={onSelectOnchangeBedLoad} />
          )}
        </>
      )}
  {price !== 0 && (
  <div className="col-12">
    <div className="row">
      <div className="col-6 text-center align-self-stretch">
        <div
          onClick={() => {
            setMembershipStatus(0);
            setTotalPrice(surchargePrice + price2 + price + bedLoadPrice);
          }}
          className={`card p-2 p-sm-3 h-100 ${membershipStatus === 0 ? "border border-warning border-5" : ""}`}
        >
          <p className="fw-bold">Pay Full Price</p>
          <div className="input-group mb-3">
            <span className="input-group-text">$</span>
            <input
              type="text"
              value={priceBeforeDiscount}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setPriceBeforeDiscount(e.target.value)}
              className="form-control text-center fs-2"
              aria-label="Amount (to the nearest dollar)"
            ></input>
          </div>
        </div>
      </div>
      <div className="col-6 text-center align-self-stretch">
        <div
          className={`card p-2 p-sm-3 h-100 ${membershipStatus === 1 ? "border border-warning border-5" : ""}`}
          onClick={() => {
            setMembershipStatus(1);
            setTotalPrice(surchargePrice + bedLoadPrice + price - 78.0 + price2);
          }}
        >
          <p className="fw-bold">Select and Save</p>
          <h2 className="textDark">
            <del className="fs-6 text-dark">${priceBeforeDiscount}</del>
            {priceBeforeDiscount - 78.0 > 0 ? `$${priceBeforeDiscount - 78.0}` : "$0"}
          </h2>
        </div>
      </div>
    </div>
  </div>
)}
{price !== 0 && (
  <div className="col-12 mt-3">
    {membershipStatus === 1 ? (
      <FormForMemberShip
        totalPrice={priceBeforeDiscount - 78.0}
        priceBeforeDiscount={priceBeforeDiscount}
      />
    ) : (
      <button
        className="btn btn-lg w-100 btn-warning"
        onClick={() =>
          window.open("intent://scan/#Intent;scheme=swipesimple;package=com.cardflight.swipesimple;end")
        }
      >
        Make Payment
      </button>
    )}
  </div>
)}
</div>
);
};

export default BulkVolumeRemoval;
