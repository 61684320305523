import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Routing from "./components/routing/Routing";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContextProvider } from "./context/Context";

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: `https://d4825e67c4635433a97f9d27ce1b85e6@o4506145467596800.ingest.sentry.io/4506594076721152`,
  integrations: [
    new Sentry.BrowserTracing({
      // Set ‘tracePropagationTargets’ to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/1800gotjunkmembership\.com\/api/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you’re not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <Routing />
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
