import React from "react";

const StripeLoadingMsg = () => {
  return (
    <div>
      This may take a few moments. Please don't refresh the screen while Stripe
      is loading.
    </div>
  );
};

export default StripeLoadingMsg;
