import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Survey = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear info in session storage
    sessionStorage.removeItem("data");
  }, []);

  return (
    <div className="row">
      <div className="col-lg-9 col-md-10 m-auto">
        <div className="dark_gradient textLight p-3 p-lg-5 text-center">
            Truck Team Members: Please click here to complete this survey to help us gather information on this new Royal Blue Member. Thank you! <br />
          <div className="dark_gradient textLight p-3 p-lg-5 text-center">
          <a href="https://forms.gle/AHitqe6NyZd6vKma6" target="_blank" rel="noopener noreferrer" className="survey_link">
              Access the survey here
            </a>
            </div>
           <br />
          <p className="mb-0">
            <button onClick={() => navigate("/")} className="back_btn mb-2">
              <i className="fa fa-angle-left"></i> Back Home
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Survey;
