import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";

const FormForMemberShip = ({ totalPrice, priceBeforeDiscount }) => {
  const { startOnboarding } = useApiAxios();
  const {
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (!data.agree) {
      return setError("agree", { type: "custom", message: "Please agree" });
    }

    // Call onboarding api to get stripeId
    const res = await startOnboarding(data, totalPrice, priceBeforeDiscount);
    if (!res.error) {
      const storageData = sessionStorage.getItem("data");
      console.log(
        "data should have been set",
        storageData && storageData.length
      );
      const path = "/setup?stripe_id=" + res.data.stripe_id;
      console.log(path);
      navigate(path);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* First Name */}
      <div className="mb-3">
        <label htmlFor="first_name" className="form-label text-light">
          First Name
        </label>
        <Controller
          name="first_name"
          control={control}
          rules={{ required: "First Name is required" }}
          render={({ field }) => (
            <input
              type="text"
              className={`form-control ${
                errors.first_name ? "is-invalid" : ""
              }`}
              {...field}
            />
          )}
        />
        {errors.first_name && (
          <div className="invalid-feedback">{errors.first_name.message}</div>
        )}
      </div>

      {/* Last Name */}
      <div className="mb-3">
        <label htmlFor="last_name" className="form-label text-light">
          Last Name
        </label>
        <Controller
          name="last_name"
          control={control}
          rules={{ required: "Last Name is required" }}
          render={({ field }) => (
            <input
              type="text"
              className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
              {...field}
            />
          )}
        />
        {errors.last_name && (
          <div className="invalid-feedback">{errors.last_name.message}</div>
        )}
      </div>

      {/* Email */}
      <div className="mb-3">
        <label htmlFor="email" className="form-label text-light">
          Email
        </label>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "Invalid email format",
            },
          }}
          render={({ field }) => (
            <input
              type="text"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              {...field}
            />
          )}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email.message}</div>
        )}
      </div>

      {/* Phone Number */}
      <div className="mb-3">
        <label htmlFor="phone_number" className="form-label text-light">
          Phone Number
        </label>
        <Controller
          name="phone_number"
          control={control}
          rules={{ required: "Phone Number is required" }}
          render={({ field }) => (
            <input
              type="text"
              className={`form-control ${
                errors.phone_number ? "is-invalid" : ""
              }`}
              {...field}
            />
          )}
        />
        {errors.phone_number && (
          <div className="invalid-feedback">{errors.phone_number.message}</div>
        )}
      </div>

      {/* Street Address */}
      <div className="mb-3">
        <label htmlFor="street_address" className="form-label text-light">
          Street Address
        </label>
        <Controller
          name="street_address"
          control={control}
          rules={{
            required: "Street Address is required",
            minLength: {
              value: 5,
              message: "Street Address must be at least 5 characters long",
            },
          }}
          render={({ field }) => (
            <input
              type="text"
              className={`form-control ${
                errors.street_address ? "is-invalid" : ""
              }`}
              {...field}
            />
          )}
        />
        {errors.street_address && (
          <div className="invalid-feedback">
            {errors.street_address.message}
          </div>
        )}
      </div>

      {/* City */}
      <div className="mb-3">
        <label htmlFor="city" className="form-label text-light">
          City
        </label>
        <Controller
          name="city"
          control={control}
          rules={{
            required: "City is required",
            minLength: {
              value: 3,
              message: "City must be at least 3 characters long",
            },
          }}
          render={({ field }) => (
            <input
              type="text"
              className={`form-control ${errors.city ? "is-invalid" : ""}`}
              {...field}
            />
          )}
        />
        {errors.city && (
          <div className="invalid-feedback">{errors.city.message}</div>
        )}
      </div>

      {/* State */}
      <div className="mb-3">
        <label htmlFor="state" className="form-label text-light">
          State
        </label>

        <Controller
          name="state"
          control={control}
          rules={{ required: "State is required", minLength: 2 }}
          render={({ field }) => (
            // <input
            //   type="text"
            //   className={`form-control ${errors.state ? "is-invalid" : ""}`}
            //   {...field}
            // />
            <select
              className={`form-control ${errors.state ? "is-invalid" : ""}`}
              {...field}
            >
              <option value="">Select State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          )}
        />
        {errors.state && (
          <div className="invalid-feedback">{errors.state.message}</div>
        )}
      </div>

      {/* Postal Code */}
      <div className="mb-3">
        <label htmlFor="postal_code" className="form-label text-light">
          Postal Code
        </label>
        <Controller
          name="postal_code"
          control={control}
          rules={{
            required: "Postal Code is required",
            pattern: { value: /^\d{5}$/, message: "Invalid Postal Code" },
          }}
          render={({ field }) => (
            <input
              type="tel"
              maxLength={6}
              className={`form-control ${
                errors.postal_code ? "is-invalid" : ""
              }`}
              {...field}
            />
          )}
        />
        {errors.postal_code && (
          <div className="invalid-feedback">{errors.postal_code.message}</div>
        )}
      </div>

      {/* Salesforce Opportunity ID */}
      <div className="mb-3">
        <label htmlFor="salesforce_opp_id" className="form-label text-light">
          Salesforce Opportunity ID
        </label>
        <Controller
          name="salesforce_opp_id"
          control={control}
          rules={{ required: "Salesforce Opportunity ID is required" }}
          render={({ field }) => (
            <input
              type="text"
              className={`form-control ${
                errors.salesforce_opp_id ? "is-invalid" : ""
              }`}
              {...field}
            />
          )}
        />
        {errors.salesforce_opp_id && (
          <div className="invalid-feedback">
            {errors.salesforce_opp_id.message}
          </div>
        )}
      </div>
      <div className="mb-3 form-check">
        <Controller
          name="agree"
          control={control}
          rules={{ required: "Please agree" }}
          render={({ field }) => (
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              {...field}
            />
          )}
        />

        <label className="form-check-label text-light" htmlFor="exampleCheck1">
          I agree to the{" "}
          <span
            onClick={() =>
              window.open(
                "https://1800gotjunkmembership.com/terms&Service",
                "_blank",
                "location=yes,height=570,width=520,scrollbars=yes,status=yes"
              )
            }
            className="text-warning btn p-0"
          >
            Terms of Service
          </span>
        </label>
        {errors?.agree?.message && (
          <div className="invalid-feedback">{errors?.agree?.message}</div>
        )}
      </div>

      <button type="submit" className="btn btn-lg w-100 btn-warning">
        Make payment
      </button>
    </form>
  );
};

export default FormForMemberShip;
